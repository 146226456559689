import axios from 'axios';

export default class Prices {

	static axiosCancelToken = axios.CancelToken.source();
	
	static getPendingCheck = () => {
		this.newAxiosCancelToken();

		return axios.get('api/prices-check/get-pending', {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static updateCheckStatuses = (checkId, statuses) => {
		this.newAxiosCancelToken();

		return axios.post('api/prices-check/update-pending', {
			id: checkId,
			...statuses
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return error.response.data;
		});
	}

	static getCompetitionIntervalList = () => {
		this.newAxiosCancelToken();

		return axios.get('api/prices/station-competition/intervals-list', {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getCompetitionStationsList = (interval_id, with_minetur_prices = false, station_competition_id) => {
		this.newAxiosCancelToken();

		return axios.get('api/prices/station-competition/stations-list', {
			params: {
				interval_id: interval_id,
				with_minetur_prices: with_minetur_prices,
				station_competition_id: station_competition_id
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static saveCompetitionStationVisit = (interval_id, station_competition_id, goa_price, sp95_price, goa_aditivado_price, sp95_aditivado_price, photo) => {
		this.newAxiosCancelToken();

		let data = new FormData();
		data.append('interval_id', interval_id);
		data.append('station_competition_id', station_competition_id);
		data.append('goa_price', goa_price);
		data.append('sp95_price', sp95_price);
		if ( goa_aditivado_price ) data.append('goa_aditivado_price', goa_aditivado_price);
		if ( sp95_aditivado_price ) data.append('sp95_aditivado_price', sp95_aditivado_price);
		data.append('photo', photo);

		return axios.post('api/prices/station-competition-visit/save', data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}